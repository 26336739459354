import React from 'react';
import { graphql } from 'gatsby';
import QuestionPage from '../../components/QuestionPage';

export const query = graphql`
  query {
    contentfulSeoData(node_locale: {eq: "th"}) {
      homeOgDescription {
        homeOgDescription
      }
      homeOgTitle
      ogImage {
        file { 
          url 
          details {
            image {
              width
              height
            }
          }
        }
      }
    }
    contentfulSiteData(node_locale: {eq: "th"}) {
      analyzingText
      elipsis
    }

    allContentfulQuestion(filter: {node_locale: {eq: "th"}}) {
      edges {
        node {
          order
          question {
            question 
          }
          answers {
            value
            text {
              text
            }
          }
        }
      }
    }
  }
`;

const QuestionEnPage = ({ data }) => (
  <QuestionPage data={data} lang="th" />
);

export default QuestionEnPage;
